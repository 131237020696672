<template>
  <BaseModal
    v-model="isVariableMappingModalActive"
    :has-modal-card="true"
    :trap-focus="true"
    aria-role="dialog"
    aria-label="Variable Mapping"
    aria-modal
  >
    <CardPopup
      title="Variable Mapping"
      class="w-800"
      @hide="isVariableMappingModalActive = false"
    >
      <template #body>
        <div>
          <div class="mapped-variable-list mb-2">
            <Draggable
              v-model="variablesToBeMapped"
              handle=".drag-handle"
            >
              <div
                v-for="(variable, vIndex) in variablesToBeMapped"
                :key="'var' + vIndex"
                class="is-flex"
              >
                <b-icon
                  icon="drag-horizontal-variant"
                  class="drag-handle mr-2"
                />
                <div
                  class="mapped-variable mb-2"
                >
                  <b-input
                    v-if="prefix.isDistinct"
                    v-model="prefix.customContent[vIndex]"
                    size="is-small"
                    class="mr-2 w-half"
                    expanded
                  />
                  <div class="mention mr-2 w-half">
                    @{{ variable.name }}
                  </div>
                  <b-input
                    v-if="suffix.isDistinct"
                    v-model="suffix.customContent[vIndex]"
                    class="mr-2 w-half"
                    size="is-small"
                    expanded
                  />
                  <b-button
                    class="is-danger"
                    size="is-small"
                    @click="removeVariableMapping(vIndex)"
                  >
                    <b-icon
                      size="is-small"
                      icon="trash-can"
                    />
                  </b-button>
                </div>
              </div>
            </Draggable>
          </div>
          <AffixOptions 
            affix-type="prefix"
            :variables-mapped="variablesToBeMapped"
            @update="handleAffixUpdate($event, 'prefix')"
          />
          <AffixOptions 
            affix-type="suffix"
            :variables-mapped="variablesToBeMapped"
            @update="handleAffixUpdate($event, 'suffix')"
          />
        </div>
      </template>
      <template #footer>
        <div class="is-flex is-justify-content-space-between w-full">
          <b-button
            class="px-6 rounded-8 btn-primary-light"
            @click="isVariableMappingModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-primary"
            class="px-6 rounded-8"
            :disabled="!(variablesToBeMapped && variablesToBeMapped.length)"
            @click="applyMapping()"
          >
            Apply
          </b-button>
        </div>
      </template>
    </CardPopup>
  </BaseModal>
</template>

<script >
// lib
import { computed, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import Draggable from 'vuedraggable';
// components
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
import AffixOptions from './AffixOptions.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  },
  selectedOption: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const moduleStore = useModuleStore();
  const prefix = ref({});
  const suffix = ref({});
  const variablesToBeMapped = ref([]);
  const isVariableMappingModalActive = computed({
    get() {
      return props.value;
    },
    set(value) {
      emit('input', value);
    }
  });
  const handleAffixUpdate = (updatedAffix, type) => {
    if (type === 'prefix') {
      prefix.value = updatedAffix;
    } else if (type === 'suffix') {
      suffix.value = updatedAffix;
    }
  };
  const removeVariableMapping = index => {
    variablesToBeMapped.value.splice(index, 1);
  };
  const applyMapping = () => {
    emit('apply-mapping', {
      variables: variablesToBeMapped.value,
      prefix: prefix.value,
      suffix: suffix.value
    });
    isVariableMappingModalActive.value = false;
  };
  debouncedWatch(() => props.selectedOption, () => {
    variablesToBeMapped.value = moduleStore.variables.filter(variable => variable.name.startsWith(props.selectedOption.name));
  }, {
    deep: true,
    debounce: 500
  });
  return {
    prefix,
    suffix,
    variablesToBeMapped,
    isVariableMappingModalActive,
    handleAffixUpdate,
    removeVariableMapping,
    applyMapping
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup,
  Draggable,
  AffixOptions
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.mapped-variable-list {
  max-width: 40rem;
  .drag-handle {
    border-radius: 0.125rem;
    cursor: pointer;
    &:hover {
      background-color: $grey;
    }
  }
  .mapped-variable {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
