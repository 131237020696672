<template>
  <div>
    <div
      class="is-flex"
    >
      <b-tag
        v-if="type"
        type="is-light"
        rounded
        ellipsis
      >
        {{ type }}
      </b-tag>
      <slot name="label">
        <span
          v-if="label"
          class="base-label default"
        >
          {{ label }}
        </span>
      </slot>
    </div>
    <div
      v-if="editor"
      class="editor"
    >
      <bubble-menu
        v-if="editor.isActive('formula')"
        v-show="true"
        class="bubble-menu"
        :editor="editor"
      >
        <button @click="editFormula()">
          Edit
        </button>
        <button @click="deleteFormula()">
          Delete
        </button>
      </bubble-menu>
      <bubble-menu
        v-else
        v-show="editor.isActive('custom-image')"
        class="bubble-menu"
        :tippy-options="{ animation: false }"
        :editor="editor"
      >
        <button
          :class="{'is-active': editor.isActive('custom-image', {size: 'small'})}"
          @click="editor.chain().focus().setImage({ size: 'small' }).run()"
        >
          Small
        </button>
        <button
          :class="{'is-active': editor.isActive('custom-image', { size: 'medium' })}"
          @click="editor.chain().focus().setImage({ size: 'medium' }).run()"
        >
          Medium
        </button>
        <button
          :class="{'is-active': editor.isActive('custom-image', { size: 'large'})}"
          @click="editor.chain().focus().setImage({ size: 'large' }).run()"
        >
          Large
        </button>
        <span style="color: #aaa">|</span>
        <button
          :class="{ 'is-active': editor.isActive('custom-image', { float: 'left'})}"
          @click="editor.chain().focus().setImage({ float: 'left' }).run()"
        >
          Left
        </button>
        <button
          :class="{'is-active': editor.isActive('custom-image', {float: 'none'})}"
          @click="editor.chain().focus().setImage({ float: 'none' }).run()"
        >
          No float
        </button>
        <button
          :class="{'is-active': editor.isActive('custom-image', { float: 'right'})}"
          @click="editor.chain().focus().setImage({ float: 'right' }).run()"
        >
          Right
        </button>
        <span style="color: #aaa">|</span>
        <button @click="openImageUploadModal">
          Change
        </button>
      </bubble-menu>

      <div v-if="!input">
        <b-tooltip label="Bold">
          <b-button
            size="is-small"
            :type="editor.isActive('bold') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <b-icon icon="format-bold" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Italic">
          <b-button
            size="is-small"
            :type="editor.isActive('italic') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <b-icon icon="format-italic" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Underline">
          <b-button
            size="is-small"
            :type="editor.isActive('underline') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleUnderline().run()"
          >
            <b-icon icon="format-underline" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Strikethrough">
          <b-button
            size="is-small"
            :type="editor.isActive('strike') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <b-icon icon="format-strikethrough" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Text Color">
          <b-button
            size="is-small"
            :type="selectedTextColor && editor.isActive('textStyle', { color: selectedTextColor }) ? 'is-primary' : 'is-white'"
            @click="toggleColorPicker"
          >
            <b-icon icon="format-color-text" />
          </b-button>
          <ColorPicker
            v-model="selectedTextColor"
            :show-color-picker="isColorPickerVisible"
            class="tiptap-editor-color-picker"
            @hide="toggleColorPicker"
          />
        </b-tooltip>
        <b-tooltip label="Font">
          <FontSelector
            v-model="selectedFont"
          >
            <b-button
              size="is-small"
              :type="selectedFont && editor.isActive('textStyle', { fontFamily: selectedFont }) ? 'is-primary' : 'is-white'"
            >
              <b-icon icon="format-font" />
            </b-button>
          </FontSelector>
        </b-tooltip>
        <b-tooltip label="Text Size">
          <FontSizeSelector
            v-model="selectedFontSize"
          >
            <b-button
              size="is-small"
              :type="selectedFontSize && editor.isActive('textStyle', { fontSize: selectedFontSize }) ? 'is-primary' : 'is-white'"
            >
              <b-icon icon="format-size" />
            </b-button>
          </FontSizeSelector>
        </b-tooltip>
        <b-tooltip label="Align text left">
          <b-button
            size="is-small"
            :type="editor.isActive({ textAlign: 'left' }) ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().setTextAlign('left').run()"
          >
            <b-icon icon="format-align-left" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Align text center">
          <b-button
            size="is-small"
            :type="editor.isActive({ textAlign: 'center' }) ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().setTextAlign('center').run()"
          >
            <b-icon icon="format-align-center" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Align text right">
          <b-button
            size="is-small"
            :type="editor.isActive({ textAlign: 'right' }) ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().setTextAlign('right').run()"
          >
            <b-icon icon="format-align-right" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Justify text">
          <b-button
            size="is-small"
            :type="editor.isActive({ textAlign: 'justify' }) ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().setTextAlign('justify').run()"
          >
            <b-icon icon="format-align-justify" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Inline code">
          <b-button
            size="is-small"
            :type="editor.isActive('code') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleCode().run()"
          >
            <b-icon icon="code-tags" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Paragraph">
          <b-button
            size="is-small"
            :type="editor.isActive('paragraph') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().setParagraph().run()"
          >
            <b-icon icon="format-paragraph" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Large header">
          <b-button
            size="is-small"
            :type="editor.isActive('heading', { level: 1 }) ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          >
            <b-icon icon="format-header-1" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Medium header">
          <b-button
            size="is-small"
            :type="editor.isActive('heading', { level: 2 }) ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            <b-icon icon="format-header-2" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Small header">
          <b-button
            size="is-small"
            :type="editor.isActive('heading', { level: 3 }) ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          >
            <b-icon icon="format-header-3" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Bullet list">
          <b-button
            size="is-small"
            :type="editor.isActive('bulletList') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            <b-icon icon="format-list-bulleted" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Ordered list">
          <b-button
            size="is-small"
            :type="editor.isActive('orderedList') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            <b-icon icon="format-list-numbered" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Clear Formatting">
          <b-button
            size="is-small"
            type="is-white"
            @click="editor.chain().focus().clearNodes().unsetAllMarks().run()"
          >
            <b-icon icon="format-clear" />
          </b-button>
        </b-tooltip>

        <b-tooltip label="Image">
          <b-button
            size="is-small"
            type="is-white"
            @click="openImageUploadModal()"
          >
            <b-icon icon="image" />
          </b-button>
        </b-tooltip>

        <b-button
          size="is-small"
          :type="editor.isActive('link') ? 'is-primary' : 'is-white'"
          @click="openLinkInputModal()"
        >
          <b-icon icon="link" />
        </b-button>
        <b-button
          v-if="editor.isActive('link')"
          size="is-small"
          type="is-white"
          @click="editor.chain().focus().unsetLink().run()"
        >
          <b-icon icon="link-off" />
        </b-button>

        <!--    <b-button size="is-small" variant="white" @click="setUrl()">-->
        <!--      <b-icon icon="link"/>-->
        <!--    </b-button>-->
        <b-tooltip label="Quote">
          <b-button
            size="is-small"
            :type="editor.isActive('blockquote') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleBlockquote().run()"
          >
            <b-icon icon="format-quote-open" />
          </b-button>
        </b-tooltip>

        <b-tooltip label="Code block">
          <b-button
            size="is-small"
            :type="editor.isActive('codeBlock') ? 'is-primary' : 'is-white'"
            @click="editor.chain().focus().toggleCodeBlock().run()"
          >
            <b-icon icon="code-braces-box" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Horizontal line">
          <b-button
            type="is-white"
            size="is-small"
            @click="editor.chain().focus().setHorizontalRule().run()"
          >
            <b-icon icon="view-stream-outline" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Undo">
          <b-button
            type="is-white"
            size="is-small"
            @click="editor.chain().focus().undo().run()"
          >
            <b-icon icon="undo" />
          </b-button>
        </b-tooltip>
        <b-tooltip label="Redo">
          <b-button
            type="is-white"
            size="is-small"
            @click="editor.chain().focus().redo().run()"
          >
            <b-icon icon="redo" />
          </b-button>
        </b-tooltip>
        <b-tooltip
          v-if="formula"
          label="Formula"
        >
          <b-button
            type="is-white"
            size="is-small"
            @click="openFormulaPrompt()"
          >
            <b-icon icon="sigma" />
          </b-button>
        </b-tooltip>

        <!--          <b-button variant="white" size="is-small" @click="insertVariables">-->
        <!--            <b-icon icon="at"></b-icon>-->
        <!--          </b-button>-->
      </div>

      <b-tooltip
        v-if="formula && !editor.isActive('formula')"
        label="Formula"
        class="is-pulled-right mt-2 mr-2"
        style="position: relative; z-index: 999;"
      >
        <b-button
          style="background: transparent!important; border: none!important"
          size="is-small"
          @click="openFormulaPrompt()"
        >
          <b-icon icon="sigma" />
        </b-button>
      </b-tooltip>

      <b-tooltip
        v-if="formula && editor.isActive('formula')"
        class="is-pulled-right"
        style="position: relative; z-index: 999;"
        label="Edit Formula"
      >
        <b-button
          type="is-dark"
          size="is-small"
          @click="editFormula()"
        >
          <b-icon icon="sigma" />
        </b-button>
      </b-tooltip>

      <EditorContent
        :editor="editor"
        :class="classes"
      />
    </div>
    <BaseModal
      v-model="showFormulaModal"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="New application"
      aria-modal
    >
      <CardPopup
        title="Insert formula"
        class="w-800"
        @hide="showFormulaModal = false"
      >
        <template #body>
          <small>Formula</small>
          <FormulaBuilder
            v-if="showFormulaModal"
            v-model="selectedFormula"
          />
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="showFormulaModal = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="insertFormula()"
            >
              Insert
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
    <LinkInputModal 
      v-model="isLinkInputModalVisible"
      :initial-url="selectedLinkUrl"
      @update="setLink"
    />
    <ImageUploadModal 
      v-model="isImageUploadModalVisible"
      @update="addImage"
    />
  </div>
</template>

<script>
// libs
import { defineAsyncComponent } from '@vue/composition-api';
import tippy from 'tippy.js';
import {BubbleMenu, Editor, EditorContent, VueRenderer} from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import {mergeAttributes, Node} from '@tiptap/core';
import Link from '@tiptap/extension-link';
import TextStyle from '@tiptap/extension-text-style';
import FontFamily from '@tiptap/extension-font-family';
import TextColor from '@tiptap/extension-color';
import FontSize from 'tiptap-extension-font-size';
// components
import VariableTreePopup from '@/modules/core/components/variable-tree-popup/VariableTreePopup.vue';
import LinkInputModal from './LinkInputModal.vue';
import ImageUploadModal from './ImageUploadModal.vue';
import FontSelector from './FontSelector.vue';
import ColorPicker from './ColorPicker.vue';
import FormulaBuilder from '@/modules/core/components/formula-builder/FormulaBuilder.vue';
// stores
import { useModuleStore } from '@/modules/builder/store/moduleStore';
// others
import CustomMention from './extensions/custom-mention';
import CustomImage from './extensions/custom-image';
import FontSizeSelector from './FontSizeSelector.vue';
import { delay } from '@/helpers/util';
import { debounce } from 'lodash';

const moduleStore = useModuleStore();

const FormulaNode = Node.create({
    name: 'formula',
    // atom: true,
    draggable: true,
    inline: true,
    // selectable: true,
    group: 'inline',
    defaultOptions: {
        HTMLAttributes: {},
    },

    addCommands() {
        return {
            setFormula: options => ({commands}) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: options
                });
            },
            editFormula: options => ({commands}) => {
                return commands.insertContentAt(options.rangeStart, {
                    type: 'text',
                    text: ''
                });
            }
        };
    },
    addAttributes() {
        return {
            class: {
                default: 'formula',
            },

            formula: {
                default: null,
                parseHTML: element => {
                    return element.getAttribute('data-formula');
                },
                renderHTML: attributes => {
                    if (!attributes.formula) {
                        return {};
                    }

                    return {
                        'data-formula': attributes.formula,
                    };
                },
            }
        };
    },
    parseHTML() {
        return [
            {
                tag: 'span.formula',
            },
        ];
    },
    renderHTML({node, HTMLAttributes}) {
        if (node.attrs.formula) {
            return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 'f(x)'];
        }
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },

});

export default {
    name: 'Editor',
    components: {
        FormulaBuilder,
        EditorContent,
        BubbleMenu,
        BaseModal: defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue')),
        CardPopup: defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue')),
        LinkInputModal,
        ImageUploadModal,
        ColorPicker,
        FontSelector,
        FontSizeSelector
    },
    props: {
        value: {
            type: [String, Number,Boolean],
            default: ''
        },
        input: {
            type: Boolean,
            default: false
        },
        formula: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String, 
            default: null
        },
        type: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            editor: null,
            showFormulaModal: false,
            classes: '',
            isLinkInputModalVisible: false,
            isImageUploadModalVisible: false,
            isColorPickerVisible: false,
            selectedLinkUrl: '',
            selectedFormula: null,
            selectedImageSrc: ''
        };
    },

    computed: {
        selectedTextColor: {
            get () {
                return this.editor.getAttributes('textStyle').color || '';
            },
            set (color) {
                this.editor.chain().focus().setColor(color).run();
            }
        },
        selectedFont: {
            get () {
                return this.editor.getAttributes('textStyle').fontFamily;
            },
            set (font) {
                this.editor.chain().focus().setFontFamily(font).run();
            }
        },
        selectedFontSize: {
            get () {
                return this.editor.getAttributes('textStyle').fontSize;
            },
            set (font) {
                this.editor.chain().focus().setFontSize(font).run();
            }
        }
    },

    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value;
            if (isSame) {
                return;
            }

            this.editor.commands.setContent(this.value, false);
        },
        placeholder(_value){
            this.refreshEditor();
        }
    },

    mounted() {
        this.setClasses();
        this.createEditor();
        this.$nextTick(async () => {
            if (this.value) {
                this.editor.commands.setContent(this.value);
            }
        });
    },

    beforeDestroy() {
        this.editor.destroy();
    },

    methods: {
        createEditor(){
            let extensions = [
                StarterKit.configure(this.input ? {
                    bold: false,
                    blockquote: false,
                    bulletList: false,
                    code: false,
                    codeBlock: false,
                    heading: false,
                    horizontalRule: false,
                    italic: false,
                    listItem: false,
                    orderedList: false,
                    strike: false
                }: undefined),
                FormulaNode,
                Placeholder.configure({
                    placeholder: this.placeholder
                }),
                CustomMention.configure({
                    HTMLAttributes: {
                        class: 'mention',
                    },
                    suggestion: {
                        items: o => {
                            return moduleStore.variables.filter(
                                item => item.name?.toLowerCase().includes(o.query.toLowerCase())
                            ).sort(function(a, b) {
                                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }

                                // names must be equal
                                return 0;
                            }).map(item => ({
                                ...item,
                                hasOptions: !!item?.name?.includes(' > ')
                            }));
                        },

                        render: () => {
                            let component;
                            let popup;

                            return {
                                onStart: props => {
                                    component = new VueRenderer(VariableTreePopup, {
                                        parent: this,
                                        propsData: props,
                                    });

                                    popup = tippy('body', {
                                        getReferenceClientRect: props.clientRect,
                                        appendTo: () => document.body,
                                        content: component.element,
                                        showOnCreate: true,
                                        interactive: true,
                                        trigger: 'manual',
                                        placement: 'bottom-start',
                                    });
                                },
                                onUpdate(props) {
                                    component.updateProps(props);

                                    popup[0].setProps({
                                        getReferenceClientRect: props.clientRect,
                                    });
                                },
                                onExit() {
                                    popup[0].destroy();
                                    component.destroy();
                                },
                            };
                        },
                    },
                })
            ];

            if (!this.input) {
                extensions = [
                    ...extensions,
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    }),
                    Highlight, 
                    TextStyle,
                    TextColor,
                    FontFamily,
                    FontSize,
                    Underline,
                    Link.configure({
                        autolink: false,
                        linkOnPaste: false,
                    }),
                    CustomImage.configure({
                        HTMLAttributes: {
                            class: 'custom-image'
                        }
                    })
                ];
            }

            this.editor = new Editor({
                extensions,
                content: this.value,
                editable: this.disabled !== 'disabled',
                disablePasteRules: true,
                onUpdate: () => {
                    this.$emit('input', this.editor.getHTML());
                }
            });
        },
        refreshEditor : debounce(function (){
            this.editor.destroy();
            this.createEditor();
        },1000),
        addImage(images) {
            if (images.length) {
                images.forEach(url => {
                    this.editor.chain().focus().setImage({src: url}).run();
                });
            }
        },
        openFormulaPrompt() {
            this.showFormulaModal = true;
        },
        insertFormula() {
            if (this.selectedFormula) {
                this.editor.chain().focus().setFormula({
                    formula: JSON.stringify(this.selectedFormula)
                }).run();
            }
            this.showFormulaModal = false;
        },
        editFormula() {
            const formula = this.editor.state.selection.node.attrs.formula;
            this.selectedFormula = formula ? JSON.parse(formula) : null;
            this.showFormulaModal = true;
        },
        deleteFormula() {
            this.editor.commands.deleteSelection();
        },
        setClasses() {
            if (this.input) {
                this.classes += 'tiptap-editor-input';
            } else {
                this.classes += 'tiptap-editor-textarea';
            }



            if (this.disabled === 'disabled') {
                this.classes += ' tiptap-editor-disabled';
            }

        },
        setLink(url) {
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({href: url})
                .run();
            this.selectedLinkUrl = '';
        },

        openLinkInputModal () {
            this.selectedLinkUrl = this.editor.getAttributes('link').href;
            this.isLinkInputModalVisible = true;
        },

        openImageUploadModal () {
            this.selectedImageSrc = '';
            this.isImageUploadModalVisible = true;
        },

        toggleColorPicker () {
            this.isColorPickerVisible = !this.isColorPickerVisible;
        }
    }
};
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }
}
.tiptap-editor-textarea {
  min-height: 200px !important;
  border-radius: 2px;
  font-size: 0.75rem;
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  max-width: 100%;
  width: 100%;
  background-color: white;
  color: #363636;
  border: 1px solid #dbdbdb;
  justify-content: flex-start;
  line-height: 1.5;
  padding: calc(0.5em - 1px) calc(0.75em - 1px);
  position: relative;
  vertical-align: top;
}

.tiptap-editor-color-picker {
  position: absolute !important;
  z-index: 5000;
}

.tiptap-editor-input {
  border-radius: 8px;
  font-weight: 400;
  font-size: 13px!important;
  line-height: 18px;
  color: #222222;
  padding: 6px 14px;

  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  max-width: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #dbdbdb;
  justify-content: flex-start;
  position: relative;
  vertical-align: top;
}

.tiptap-editor-disabled {
  background: #dedede;
  p {
    color: #777777;
  }
}

.tiptap-editor-input {
  .ProseMirror {
    overflow-x: hidden;
  }
}

.ProseMirror-focused {
  outline: none !important;
}

.ProseMirror {
  font-size: 13px !important;
  height: 100% !important;
  overflow-y: auto;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
  }

  h1 {
    font-size: 2rem;
    font-weight: bolder;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.3rem;
  }

  blockquote {
    display: block;
    padding-left: 1rem;
    margin: 1rem 0;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
  }

  ul {
    list-style-type: disc;
  }

  ol {
    padding-left: 20px !important;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #FAF594;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  .custom-image-small {
    max-width: 200px;
  }

  .custom-image-medium {
    max-width: 500px;
  }

  .custom-image-large {
    max-width: 100%;
  }

  .custom-image-float-none {
    float: none;
  }

  .custom-image-float-left {
    float: left;
  }

  .custom-image-float-right {
    float: right;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}


.mention {
  color: #444444;
  background-color: rgba(#000000, 0.1);
  border-radius: 0.3rem;
  padding: 0.1rem 0.3rem;
}

.formula {
  color: #5550FC;
  background: rgba(#5550FC, 0.1);
  border-radius: 0.3rem;
  padding: 0.1rem 0.3rem;
  max-width: 200px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display: inline-flex !important;
}

/* Placeholder (at the top) */
.tiptap p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

</style>