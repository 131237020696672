<template>
  <div>
    <h3 class="mt-1 mb-2">
      <strong>{{ affixLabel }}:</strong>
    </h3>
    <div class="mb-1">
      <b-switch v-model="affix.isDistinct">
        Use Distinct {{ affixLabel }}
      </b-switch>
    </div>
    <div>
      <small>Select {{ affixLabel }}: </small>
      <b-select
        v-model="affix.contentType"
        size="is-small"
        expanded
        @input="handleContentUpdate"
      >
        <option
          v-for="(affixOption, index) in affixOptions"
          :key="index"
          :value="affixOption.value"
        >
          {{ affixOption.label }}
        </option>
      </b-select>
      <div
        v-if="affix.contentType === 'custom' && !affix.isDistinct"
        class="my-3"
      >
        <small>Custom {{ affixLabel }} Character(s)</small>
        <b-input
          v-model="affix.customContent"
        />
      </div>
    </div>
    <small>Select Delimiter: </small>
    <b-select
      v-model="affix.delimiter"
      size="is-small"
      expanded
    >
      <option
        v-for="(delimiterOption, index) in delimiterOptions"
        :key="index"
        :value="delimiterOption.value"
      >
        {{ delimiterOption.label }}
      </option>
    </b-select>
    <div
      v-if="affix.delimiter === 'custom'"
      class="my-3"
    >
      <small>Custom delimiter character(s)</small>
      <b-input
        v-model="affix.customDelimiter"
      />
    </div>
  </div>
</template>

<script >
import { computed, ref, watch } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  affixType: {
    type: String,
    default: ''
  },
  variablesMapped: {
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const affixLabel = computed(() => props.affixType === 'prefix' ? 'Prefix' : 'Suffix');
  const delimiterOptions = ref([{
    label: 'None',
    value: ''
  },
  // @TO-DO's: Add support for newline and tab delimiters if required later
  // {
  //     label: 'New Line',
  //     value: '\n'
  // },
  // {
  //     label: 'Tab',
  //     value: '\t'
  // },
  {
    label: 'Custom',
    value: 'custom'
  }]);
  const affixOptions = ref([{
    label: 'None',
    value: ''
  }, {
    label: 'Variable Names',
    value: 'variable_names'
  }, {
    label: 'Custom',
    value: 'custom'
  }]);
  const affix = ref({
    isDistinct: false,
    delimiter: '',
    customDelimiter: '',
    contentType: '',
    customContent: ''
  });
  const handleContentUpdate = () => {
    if (affix.value.isDistinct) {
      affix.value.customContent = props.variablesMapped.map(_ => '');
    }
    if (affix.value.contentType === 'variable_names') {
      affix.value.isDistinct = true;
      affix.value.customContent = props.variablesMapped.map(variable => variable.name.split(' > ').slice(-1).join(''));
    } else {
      affix.value.isDistinct = false;
      affix.value.customContent = '';
    }
  };
  watch(() => affix.value, () => {
    emit('update', {
      ...affix.value
    });
  }, {
    deep: true
  });
  watch(() => props.variablesMapped, () => {
    handleContentUpdate();
  });
  return {
    affixLabel,
    delimiterOptions,
    affixOptions,
    affix,
    handleContentUpdate
  };
};
export default __sfc_main;
</script>
