<template>
  <div class="position-relative">
    <small>Array Reference</small>
    <Editor
      v-model="formulaInfo.data.arrayReference"
      :input="true"
      :formula="true"
    />
    <small>Loop Item Reference</small>
    <Editor
      v-model="formulaInfo.data.currentItemReference"
      :input="true"
      :formula="false"
      class="mb-3"
    />
    <small>Loop Index Reference (optional)</small>
    <Editor
      v-model="formulaInfo.data.indexReference"
      :input="true"
      :formula="false"
      class="mb-3"
    />
    <small>Search Criteria</small>
    <Editor
      v-model="formulaInfo.data.searchCriteria"
      :input="true"
      :formula="true"
    />
  </div>
</template>
  
<script >
// libs
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
// components
import JsonBuilder from '@/modules/core/components/json-builder/JsonBuilder.vue';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'SEARCH ARRAY',
    data: {
      arrayReference: '',
      currentItemReference: '',
      indexReference: '',
      searchCriteria: ''
    }
  });
  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
