var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-2"},[_c('small',[_vm._v("Enter date")]),_c('Editor',{attrs:{"input":true,"formula":true},model:{value:(_vm.formulaInfo.data.value),callback:function ($$v) {_vm.$set(_vm.formulaInfo.data, "value", $$v)},expression:"formulaInfo.data.value"}})],1),_c('BaseSelect',{staticClass:"mb-2",attrs:{"size":"is-small","label":"Select Timezone","label-props":{
      customClass: 'normal'
    }},model:{value:(_vm.formulaInfo.data.timezone),callback:function ($$v) {_vm.$set(_vm.formulaInfo.data, "timezone", $$v)},expression:"formulaInfo.data.timezone"}},_vm._l((_vm.timezones),function(tzName,index){return _c('option',{key:'tZone' + index},[_vm._v(" "+_vm._s(tzName)+" ")])}),0),_c('BaseInput',{staticClass:"mb-2",attrs:{"label":"Set Year","label-props":{
      customClass: 'normal'
    }},model:{value:(_vm.formulaInfo.data.year),callback:function ($$v) {_vm.$set(_vm.formulaInfo.data, "year", $$v)},expression:"formulaInfo.data.year"}}),_c('BaseInput',{staticClass:"mb-2",attrs:{"label":"Set Month","label-props":{
      customClass: 'normal'
    }},model:{value:(_vm.formulaInfo.data.month),callback:function ($$v) {_vm.$set(_vm.formulaInfo.data, "month", $$v)},expression:"formulaInfo.data.month"}}),_c('BaseInput',{staticClass:"mb-2",attrs:{"label":"Set Date","label-props":{
      customClass: 'normal'
    }},model:{value:(_vm.formulaInfo.data.date),callback:function ($$v) {_vm.$set(_vm.formulaInfo.data, "date", $$v)},expression:"formulaInfo.data.date"}}),_c('BaseInput',{staticClass:"mb-2",attrs:{"label":"Set Hour","label-props":{
      customClass: 'normal'
    }},model:{value:(_vm.formulaInfo.data.hour),callback:function ($$v) {_vm.$set(_vm.formulaInfo.data, "hour", $$v)},expression:"formulaInfo.data.hour"}}),_c('BaseInput',{staticClass:"mb-2",attrs:{"label":"Set Minute","label-props":{
      customClass: 'normal'
    }},model:{value:(_vm.formulaInfo.data.minute),callback:function ($$v) {_vm.$set(_vm.formulaInfo.data, "minute", $$v)},expression:"formulaInfo.data.minute"}}),_c('BaseInput',{staticClass:"mb-2",attrs:{"label":"Set Second","label-props":{
      customClass: 'normal'
    }},model:{value:(_vm.formulaInfo.data.second),callback:function ($$v) {_vm.$set(_vm.formulaInfo.data, "second", $$v)},expression:"formulaInfo.data.second"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }