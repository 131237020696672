<template>
  <div>
    <div class="mt-2">
      <small>String Reference</small>
      <editor
        v-model="formulaInfo.data.stringReference"
        :input="true"
        :formula="true"
      />
    </div>
    <div class="mt-2">
      <b-switch
        v-model="formulaInfo.data.isDelimiterRegex"
      >
        Use Regex Search
      </b-switch>
      <div
        v-if="formulaInfo.data.isDelimiterRegex"
        class="mt-2"
      >
        <small>Regex</small>
        <editor
          v-model="formulaInfo.data.regex"
          :input="true"
          :formula="true"
        />
      </div>
      <div
        v-else
        class="mt-2"
      >
        <small>Delimeter</small>
        <editor
          v-model="formulaInfo.data.delimeter"
          :input="true"
          :formula="true"
        />
      </div>
    </div>
  </div>
</template>
    
<script >
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'SPLIT TEXT',
    data: {
      stringReference: '',
      regex: '',
      isDelimiterRegex: false,
      delimeter: ''
    }
  });
  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
    