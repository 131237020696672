<template>
  <div>
    <div class="mt-2">
      <small>Array Reference</small>
      <editor
        v-model="formulaInfo.data.arrayReference"
        :input="true"
        :formula="true"
      />
    </div>
    <div class="mt-2">
      <b-switch
        v-model="formulaInfo.data.sortByAccessPath"
        @input="clearAccessPath"
      >
        Sort by access path
      </b-switch>
      <div
        v-if="formulaInfo.data.sortByAccessPath"
        class="mt-2"
      >
        <small>Access Path</small>
        <editor
          v-model="formulaInfo.data.accessPath"
          :input="true"
          :formula="true"
        />
      </div>
    </div>
    <div class="mt-2">
      <small>Sort direction</small>
      <b-select
        v-model="formulaInfo.data.isAsc"
        expanded
        size="is-small"
      >
        <option :value="true">
          Ascending
        </option>
        <option :value="false">
          Descending
        </option>
      </b-select>
    </div>
  </div>
</template>
  
<script >
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'SORT ARRAY',
    data: {
      arrayReference: '',
      accessPath: '',
      sortByAccessPath: false,
      isAsc: true
    }
  });
  const clearAccessPath = () => {
    formulaInfo.value.data.accessPath = '';
  };
  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo,
    clearAccessPath
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
  