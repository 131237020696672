import { computed, onUnmounted, ref, watch, nextTick, useAttrs, onMounted } from '@vue/composition-api';
import Cleave from 'cleave.js';
import { isEqual } from 'lodash';

/**
 * @param {object} props
 * @param {Function} emit
 * @param {string} elementSelector
 */
export const useMask = (props, emit, elementSelector) => {
    //-- element attribute validation --//
    const attrs = useAttrs();
    onMounted(() => {
        if (attrs.type === 'number' && props.mask && Object.keys(props.mask).length) {
            console.warn('Mask is not supported on number input.');
        }
    });

    // -- cleave logic --//
    const cleaveInstance = ref(null);
    const destroyCleave = () => {
        if (cleaveInstance.value) {
            if (isMasked.value) {
                cleaveInstance.value.setRawValue('');
            }
            cleaveInstance.value.destroy();
        }
    };
    onUnmounted(() => {
        destroyCleave();
    });

    // -- masking logic --//
    const isMasked = computed(() => props.mask && Object.keys(props.mask).length);
    const maskedValue = computed({
        get () {
            return isMasked.value && cleaveInstance.value ? cleaveInstance.value.properties.result : props.value;
        },
        set (value) {
            let emittedValue = '';
            if (isMasked.value && cleaveInstance.value) {
                emittedValue = cleaveInstance.value.getRawValue();
            } else {
                emittedValue = value;
            }
            emit('input', emittedValue);
        }
    });
    watch(
        () => props.mask,
        async (oldMask, newMask) => {
            try {
                if (isMasked.value && !isEqual(oldMask, newMask)) {
                    destroyCleave();
                    await nextTick(); // wait for DOM to update
                    cleaveInstance.value = new Cleave(elementSelector, {
                        rawValueTrimPrefix: true,
                        ...props.mask
                    });
                    cleaveInstance.value.setRawValue(props.value);
                }
            } catch (err) {
                console.error(err);
            }
        },
        {
            deep: true,
            immediate: true
        }
    );

    return {
        maskedValue
    };
};
