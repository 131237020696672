<template>
  <div>
    <span class="label my-2">Condition</span>
    <small>Your value</small>
    <editor
      v-model="formulaInfo.data.leftStatement"
      :input="true"
      :formula="true"
    />
    <small>Operator</small>
    <b-select
      v-model="formulaInfo.data.operator"
      size="is-small"
      expanded
    >
      <option
        v-for="(operator, index) in operators"
        :key="index"
      >
        {{ operator }}
      </option>
    </b-select>
    <div v-if="formulaInfo.data.operator !== 'empty' && formulaInfo.data.operator !== 'not empty'">
      <small>Expected value</small>
      <editor
        v-model="formulaInfo.data.rightStatement"
        :input="true"
        :formula="true"
      />
    </div>
    <span class="label my-2">Results</span>
    <small>If check is true:</small>
    <editor
      v-model="formulaInfo.data.trueResult"
      :input="true"
      :formula="true"
    />
    <small>If check is false:</small>
    <editor
      v-model="formulaInfo.data.falseResult"
      :input="true"
      :formula="true"
    />
  </div>
</template>

<script >
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'IF CONDITION IS TRUE',
    data: {
      leftStatement: '',
      operator: '',
      rightStatement: '',
      trueResult: '',
      falseResult: ''
    }
  });
  const operators = ref(['equals', 'does not equal', 'less than', 'more than', 'less than or equal to', 'more than or equal to', 'empty', 'not empty', 'contains', 'does not contain', 'begins with', 'ends with']);
  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo,
    operators
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
