<template>
  <div>
    <small class="mt-2">Input date:</small>
    <Editor
      v-model="formulaInfo.data.inputDate.date"
      :input="true"
      :formula="true"
    />
    <small>Input format</small>
    <b-select
      v-model="formulaInfo.data.inputDate.format"
      expanded
      size="is-small"
    >
      <option
        v-for="(format, index) in dateFormats"
        :key="index"
      >
        {{ format }}
      </option>
    </b-select>
    <div v-if="formulaInfo.data.inputDate.format === 'Custom'">
      <div>
        <small class="mt-2">Custom input format</small>
        <b-tooltip
          target="label-tooltip"
          position="is-right"
          triggers="hover"
          class="ml-2"
          :label="tooltipContent"
          multilined
        >
          <b-icon
            icon="help-circle-outline"
            size="is-small"
          />
        </b-tooltip>
      </div>
      <editor
        v-model="formulaInfo.data.inputDate.customFormat"
        :input="true"
        :formula="true"
      />
    </div>
    <small class="my-2">Result:</small>
    <b-select
      v-model="formulaInfo.data.outputDate.result"
      expanded
      size="is-small"
    >
      <option
        v-for="(type, index) in results"
        :key="index"
      >
        {{ type }}
      </option>
    </b-select>
    <template
      v-if="formulaInfo.data.outputDate.result === 'Date'"
    >
      <small>Output format</small>
      <b-select
        v-model="formulaInfo.data.outputDate.format"
        expanded
        size="is-small"
      >
        <option
          v-for="(format, index) in dateFormats"
          :key="index"
        >
          {{ format }}
        </option>
      </b-select>
    </template>
    <div v-if="formulaInfo.data.outputDate.format === 'Custom'">
      <div>
        <small class="mt-2">Custom output format</small>
        <b-tooltip
          target="label-tooltip"
          triggers="hover"
          class="ml-2"
          :label="tooltipContent"
          multilined
        >
          <b-icon
            icon="help-circle-outline"
            size="is-small"
          />
        </b-tooltip>
      </div>
      <Editor
        v-model="formulaInfo.data.outputDate.customFormat"
        :input="true"
        :formula="true"
      />
    </div>
  </div>
</template>

<script >
import { defineAsyncComponent, nextTick, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const tooltipContent = `
Format with 'hh': The 'hh' represents the hour in a 12-hour clock format (1-12). Use 'hh' when working with AM/PM time.
Format with 'HH': The 'HH' represents the hour in a 24-hour clock format (0-23). Use 'HH' for 24-hour time representation.
`;
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'FORMAT DATE',
    data: {
      inputDate: {
        date: '',
        format: '',
        customFormat: ''
      },
      outputDate: {
        date: '',
        format: '',
        customFormat: '',
        result: ''
      }
    }
  });
  const results = ref(['Date', 'Number']);
  const dateFormats = ref(['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD', 'HH:mm:ss', 'HH:mm', 'DD.MM.YYYY', 'Number', 'Custom']);
  let avoidSavingFormula = false;
  onMounted(async () => {
    try {
      await nextTick();
      if (Object.keys(props.value?.data || {}).length) {
        avoidSavingFormula = true;
        formulaInfo.value = lodash.cloneDeep(props.value);
      }
    } catch (err) {
      console.error(err);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    tooltipContent,
    formulaInfo,
    results,
    dateFormats
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
