<template>
  <div>
    <small>Number to format</small>
    <editor
      v-model="formulaInfo.data.number"
      :input="true"
      :formula="true"
      class="mb-3"
    />
    <small>Format</small>
    <b-select
      v-model="formulaInfo.data.format"
      size="is-small"
      expanded
      class="mb-3"
    >
      <option
        v-for="(formatType, index) in formatTypes"
        :key="index"
        :value="formatType.value"
      >
        {{ formatType.label }}
      </option>
    </b-select>
    <div v-if="formulaInfo.data.format && formulaInfo.data.format === 'Custom'">
      <small>Digit group separator</small>
      <editor
        v-model="formulaInfo.data.delimiter"
        :input="true"
        :formula="true"
      />
    </div>
    <div v-if="formulaInfo.data.format && formulaInfo.data.format === 'Custom'">
      <small>Decimal separator</small>
      <editor
        v-model="formulaInfo.data.numeralDecimalMark"
        :input="true"
        :formula="true"
        class="mb-3"
      />
    </div>
    <div>
      <b-switch
        v-model="formulaInfo.data.currency.show"
        size="is-small"
      >
        Show currency?
      </b-switch>
    </div>
    <template v-if="formulaInfo.data.currency.show">
      <b-select
        v-model="formulaInfo.data.currency.type"
        expanded
        size="is-small"
      >
        <option
          v-for="(currencyType, index) in currencyTypes"
          :key="index"
        >
          {{ currencyType }}
        </option>
      </b-select>
      <div
        v-if="formulaInfo.data.currency.type === 'Custom'"
        class="mt-1"
      >
        <small>Custom Currency</small>
        <editor
          v-model="formulaInfo.data.currency.custom"
          :input="true"
          :formula="true"
        />
        <b-switch
          v-model="formulaInfo.data.currency.currencySymbolAsSuffix"
          class="mb-1 mt-1"
          size="is-small"
        >
          Currency Symbol as Suffix
        </b-switch>
      </div>
    </template>
    <small class="mt-3">Decimal places</small>
    <editor
      v-model="formulaInfo.data.decimalPlaces"
      :input="true"
      :formula="true"
    />
  </div>
</template>

<script >
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formatTypes = [{
    label: '. (Period: e.g. 123.456.789,00)',
    value: 'Period'
  }, {
    label: ', (Comma: e.g. 123,456,789.00)',
    value: 'Comma'
  }, {
    label: 'None',
    value: 'None'
  }, {
    label: 'Custom',
    value: 'Custom'
  }];
  const currencyTypes = ['EUR', 'USD', 'CHF', 'ZAR', 'Custom'];
  const formulaInfo = ref({
    formula: 'FORMAT NUMBER',
    data: {
      number: '',
      format: 'No formatting',
      currency: {
        show: false,
        type: 'EUR',
        currencySymbolAsSuffix: false
      },
      decimalPlaces: '2'
    }
  });
  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formatTypes,
    currencyTypes,
    formulaInfo
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>