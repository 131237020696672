import Mention from '@tiptap/extension-mention';
import { mergeAttributes } from '@tiptap/core';

export const CustomMentions = Mention.extend({
    addAttributes () {
        return {
            reference: {
                default: null,
                parseHTML: element => {
                    return element.getAttribute('data-mention-id');
                },
                renderHTML: attributes => {
                    if (!attributes.reference) {
                        return {};
                    }

                    return {
                        'data-mention-id': attributes.reference
                    };
                }
            },
            name: {
                default: null,
                parseHTML: element => {
                    // extra @ char is being prepended from somewhere so we need to remove it
                    element.innerText = element.innerText.substring(1);

                    return element.innerText;
                }
            }
        };
    },
    parseHTML () {
        return [
            {
                tag: 'span[data-mention-id]'
            }
        ];
    },
    renderHTML ({ node, HTMLAttributes }) {
        return [
            'span',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            `${this.options.suggestion.char}${node.attrs.name}`
        ];
    }

});
