<template>
  <BaseModal
    v-model="isModalVisible"
    :has-modal-card="true"
    :trap-focus="true"
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Enter URL"
    aria-modal
  >
    <CardPopup
      title="Enter URL"
      class="w-600"
      @hide="reset()"
    >
      <template #body>
        <BaseLabel>
          URL
        </BaseLabel>
        <Editor
          v-model="url"
          class="mb-4"
          :input="true"
          :formula="true"
        />
      </template>
      <template #footer>
        <div class="is-flex is-justify-content-space-between w-full">
          <b-button
            class="px-6 rounded-8 btn-primary-light"
            @click="reset()"
          >
            Cancel
          </b-button>
          <b-button
            type="is-primary"
            class="px-6 rounded-8"
            @click="handleUpdate()"
          >
            OK
          </b-button>
        </div>
      </template>
    </CardPopup>
  </BaseModal>
</template>

<script >
import { computed, defineAsyncComponent, ref, watch } from '@vue/composition-api';
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal.vue';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
import BaseLabel from '@/modules/core/components/generics/BaseLabel.vue';
const Editor = defineAsyncComponent(() => import('./Editor.vue'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  },
  initialUrl: {
    type: String,
    default: ''
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const url = ref('');
  const isModalVisible = computed({
    get() {
      return props.value;
    },
    set(val) {
      emit('input', val);
    }
  });
  watch(() => isModalVisible.value, () => {
    if (isModalVisible.value) {
      url.value = props.initialUrl;
    }
  });
  const handleUpdate = () => {
    emit('update', url.value);
    reset();
  };
  const reset = () => {
    url.value = '';
    isModalVisible.value = false;
  };
  return {
    url,
    isModalVisible,
    handleUpdate,
    reset
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup,
  BaseLabel,
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
