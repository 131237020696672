<template>
  <div class="mt-4">
    <div
      v-for="(expression, eIndex) in formulaInfo.data.expressions"
      :key="eIndex"
      class="mb-2"
    >
      <template v-if="eIndex !== 0">
        <small>Connector</small>
        <b-select
          v-model="expression.operator"
          size="is-small"
          class="mb-2"
          expanded
        >
          <option
            v-for="(operator, index) in operators"
            :key="index"
          >
            {{ operator }}
          </option>
        </b-select>
      </template>
      <div class="is-flex is-justify-content-space-between is-align-items-end">
        <small>Expression</small>
        <b-button
          v-if="formulaInfo.data.expressions.length > 2"
          size="is-small"
          type="is-danger"
          class="mb-1"
          icon-right="trash-can"
          @click="deleteExpression(eIndex)"
        />
      </div>
      <Editor
        v-model="expression.value"
        :input="true"
        :formula="true"
      />
    </div>
    <b-button
      size="is-small"
      type="is-primary"
      @click="addExpression()"
    >
      Add Expression
    </b-button>
  </div>
</template>
  
<script >
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'COMBINE EXPRESSIONS',
    data: {
      expressions: [{
        value: '',
        operator: ''
      }, {
        value: '',
        operator: 'AND'
      }]
    }
  });
  const operators = ref(['AND', 'OR']);
  const addExpression = () => {
    formulaInfo.value.data.expressions.push({
      value: '',
      operator: 'AND'
    });
  };
  const deleteExpression = index => {
    formulaInfo.value.data.expressions.splice(index, 1);
  };
  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo,
    operators,
    addExpression,
    deleteExpression
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
  