<template>
  <span
    class="base-label"
    :class="variant"
  >
    <slot />
  </span>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  variant: {
    type: String,
    default: 'default'
  }
};
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/modules/core/components/generics/styles/label.scss';
</style>
