<template>
  <div>
    <small>Date to convert</small>
    <editor
      v-model="formulaInfo.data.date.value"
      :input="true"
      :formula="true"
    />

    <small class="mt-2">From timezone</small>
    <b-select
      v-model="formulaInfo.data.date.fromTz"
      expanded
      size="is-small"
    >
      <option
        v-for="(tzName, index) in timezones"
        :key="index"
      >
        {{ tzName }}
      </option>
    </b-select>

    <small class="mt-2">To timezone</small>
    <b-select
      v-model="formulaInfo.data.date.toTz"
      expanded
      size="is-small"
    >
      <option
        v-for="(tzName, index) in timezones"
        :key="index"
      >
        {{ tzName }}
      </option>
    </b-select>
    <small class="mt-2">Date format</small>
    <b-select
      v-model="formulaInfo.data.date.format"
      expanded
      size="is-small"
    >
      <option
        v-for="(formatOption, index) in formatOptions"
        :key="index"
      >
        {{ formatOption }}
      </option>
    </b-select>
    <div v-if="formulaInfo.data.date.format === 'Custom'">
      <small class="mt-2">Your format</small>
      <editor
        v-model="formulaInfo.data.date.customFormat"
        :input="true"
        :formula="true"
      />
    </div>
  </div>
</template>

<script >
import { defineAsyncComponent, nextTick, onMounted, ref } from '@vue/composition-api';
import moment from 'moment-timezone';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'CONVERT DATE TIMEZONE',
    data: {
      date: {
        value: '',
        fromTz: 'UTC',
        toTz: 'Europe/Berlin',
        format: '',
        customFormat: ''
      }
    }
  });
  const timezones = ref([]);
  const formatOptions = ref(['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD', 'HH:mm:ss', 'HH:mm', 'DD.MM.YYYY', 'ISO Date', 'Custom']);
  let avoidSavingFormula = false;
  onMounted(async () => {
    try {
      await nextTick();
      timezones.value = moment.tz.names();
      if (Object.keys(props.value?.data || {}).length) {
        avoidSavingFormula = true;
        formulaInfo.value = lodash.cloneDeep(props.value);
      }
    } catch (err) {
      console.error(err);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo,
    timezones,
    formatOptions
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
