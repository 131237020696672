<template>
  <div class="position-relative">
    <small>Array Reference</small>
    <Editor
      v-model="formulaInfo.data.arrayReference"
      :input="true"
      :formula="true"
    />
    <small>Loop Index Reference</small>
    <Editor
      v-model="formulaInfo.data.indexReference"
      :input="true"
      :formula="false"
      class="mb-3"
    />
    <strong>Map each item to:</strong>
    <br>
    <small>Type</small>
    <b-select
      :value="formulaInfo.data.mappingItem.type"
      size="is-small"
      expanded
      @input="updateMappingType"
    >
      <option
        v-for="(mappingType, mIndex) in mappingTypes"
        :key="'mapping-type-' + mIndex"
      >
        {{ mappingType }}
      </option>
    </b-select>
    <small>Value</small>
    <JsonBuilder
      v-if="formulaInfo.data.mappingItem.type === 'Object'"
      v-model="formulaInfo.data.mappingItem.value"
    />
    <Editor
      v-else
      v-model="formulaInfo.data.mappingItem.value"
      :input="true"
      :formula="true"
    />
  </div>
</template>

<script >
// libs
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
// components
import JsonBuilder from '@/modules/core/components/json-builder/JsonBuilder.vue';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'MAP ARRAY',
    data: {
      arrayReference: '',
      indexReference: '',
      mappingItem: {
        type: '',
        value: ''
      }
    }
  });
  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });

  // mapping logic
  const mappingTypes = ref(['Number', 'String', 'Object']);
  const updateMappingType = newType => {
    formulaInfo.value.data.mappingItem.type = newType;
    formulaInfo.value.data.mappingItem.value = newType === 'Object' ? [] : '';
  };
  return {
    formulaInfo,
    mappingTypes,
    updateMappingType
  };
};
__sfc_main.components = Object.assign({
  Editor,
  JsonBuilder
}, __sfc_main.components);
export default __sfc_main;
</script>
