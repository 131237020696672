<template>
  <div>
    <small>Value to transform to uppercase</small>
    <editor
      v-model="formulaInfo.data.value"
      :input="true"
      :formula="true"
    />
  </div>
</template>

<script >
import { defineAsyncComponent, onMounted, ref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import lodash from 'lodash';
const Editor = defineAsyncComponent(() => import('@/modules/core/components/wysiwyg/Editor'));
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const formulaInfo = ref({
    formula: 'TO UPPER',
    data: {
      value: ''
    }
  });
  let avoidSavingFormula = false;
  onMounted(() => {
    if (Object.keys(props.value?.data || {}).length) {
      avoidSavingFormula = true;
      formulaInfo.value = lodash.cloneDeep(props.value);
    }
  });
  debouncedWatch(() => formulaInfo.value, () => {
    if (avoidSavingFormula) {
      avoidSavingFormula = false;
    } else {
      emit('input', formulaInfo.value);
    }
  }, {
    deep: true,
    debounce: 500
  });
  return {
    formulaInfo
  };
};
__sfc_main.components = Object.assign({
  Editor
}, __sfc_main.components);
export default __sfc_main;
</script>
